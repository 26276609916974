<div class="number-editor">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="number-editor__input">
    <mat-label>
      {{ params?.labelForInput ?? 'Enter number' }}
    </mat-label>
    <input
      matInput
      [formControl]="textControl"
      (keydown)="onKeyDown($event)"
      type="number"
      [step]="params?.step ?? 1" />
  </mat-form-field>
</div>
