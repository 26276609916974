import { FourPlContract } from '../models/4pl-contract.model';
import { FourPLCustomer } from '../models/4pl-customer.model';
import { Activity } from '../models/activity.model';
import { JobFile } from '../models/job-file.model';
import { OrderCreateModel } from '../models/order.create.model';
import { Order } from '../models/order.model';
import { ServiceCard } from '../models/service-card/service-card.model';
import { ServiceInfoView } from '../models/service-info.view.model';
import { ServiceOptionView } from '../models/service-option.view.model';
import { Shipment } from '../models/shipment.model';
import { ShipmentView } from '../models/shipment.view.model';
import { UploadedFileType } from '../models/uploaded-file.type';

export class CreateOrder {
  static readonly type = '[Wizard] Save Order';
  constructor(public order: OrderCreateModel) {}
}

export class FetchAllOrders {
  static readonly type = '[Wizard] Fetch All Orders';
  constructor(public jobRefId: string) {}
}

export class FetchPaginatedOrders {
  static readonly type = '[Wizard] Fetch Paginated Orders';
  constructor(
    public jobRefId: string,
    public offset: number,
    public limit: number,
    public filter: string
  ) {}
}

export class FetchPaginatedOrdersOfShipment {
  static readonly type = '[Wizard] Fetch Paginated Orders Of Shipment';
  constructor(
    public shipmentId: string,
    public offset: number,
    public limit: number,
    public filter: string
  ) {}
}

export class FetchJobFile {
  static readonly type = '[Wizard] Fetch Job File';
  constructor(public jobRefId: string) {}
}

export class SaveJobFile {
  static readonly type = '[Wizard] Save JobFile';
  constructor(public jobFile: JobFile) {}
}

export class FetchJobFileByParentJobRefIdAndServiceRequestId {
  static readonly type = '[Wizard] Find JobFile By Parent JobRefId And ServiceRequestId';
  constructor(
    public parentJobRefId: string,
    public serviceRequestId: string
  ) {}
}

export class SaveJobFileWithTenderRequest {
  static readonly type = '[Wizard] Save JobFile With Tender Request';
  constructor(
    public jobFile: JobFile,
    public parentJobRefId: string,
    public tenderId: string,
    public serviceRequestId: string
  ) {}
}

export class CreateShipment {
  static readonly type = '[Wizard] Create Shipment';
  constructor(public shipment: Shipment) {}
}

export class FetchAllShipments {
  static readonly type = '[Wizard] Fetch All Shipments';
  constructor(public jobRefId: string) {}
}

export class FetchShipmentsByIds {
  static readonly type = '[Wizard] Fetch Shipments By Ids';
  constructor(public shipmentIds: string[]) {}
}

export class FetchAllOrdersByTenderId {
  static readonly type = '[Wizard] Fetch All Orders By Tender Id';
  constructor(public tenderId: string) {}
}

export class FetchServiceCard {
  static readonly type = '[Wizard] Fetch Service Card';
  constructor(
    public shipmentId: string,
    public serviceOptionId: string,
    public serviceId: string
  ) {}
}

export class AddOrdersToShipment {
  static readonly type = '[Wizard] Add Orders To Shipment';
  constructor(
    public shipmentId: string,
    public orders: Order[],
    public selectAll: boolean
  ) {}
}

export class FetchAssignedServices {
  static readonly type = '[Wizard] Fetch Assigned Services';
  constructor(public shipmentMode: string) {}
}

export class CreateDefaultServices {
  static readonly type = '[Wizard] Create Default Services';
  constructor(
    public selectedServices: string[],
    public shipmentMode: string,
    public shipmentId: string,
    public serviceOptionId: string
  ) {}
}

export class UpdateDefaultServices {
  static readonly type = '[Wizard] Update Default Services';
  constructor(
    public selectedServices: string[],
    public shipmentMode: string,
    public shipmentId: string,
    public serviceOptionId: string,
    public previousPlannedServiceId?: string
  ) {}
}

export class UpdateServiceCardTab {
  static readonly type = '[Wizard] Update Service Card Tab';
  constructor(
    public tab: number,
    public shipmentId: string,
    public serviceOptionId: string,
    public plannedServiceId: string,
    public updatedServiceCard: unknown
  ) {}
}

export class SelectShipment {
  static readonly type = '[Wizard] Select Shipment';
  constructor(public shipmentId: string) {}
}

export class DeselectShipment {
  static readonly type = '[Wizard] Deselect Shipment';
  constructor(public shipmentId: string) {}
}
export class SelectService {
  static readonly type = '[Wizard] Select Service';
  constructor(
    public shipmentId: string,
    public optionId: string,
    public service: ServiceInfoView
  ) {}
}

export class SelectOption {
  static readonly type = '[Wizard] Select Option';
  constructor(
    public shipmentId: string,
    public option: ServiceOptionView
  ) {}
}

export class CreateAssignServiceOption {
  static readonly type = '[Wizard] Create Assign Option';
  constructor(
    public shipmentId: string,
    public option: ServiceOptionView
  ) {}
}

export class CreateAssignedService {
  static readonly type = '[Wizard] Create Assigned Service';
  constructor(
    public optionId: string,
    public service: ServiceInfoView
  ) {}
}

export class DeselectOption {
  static readonly type = '[Wizard] Deselect Option';
  constructor(public shipmentId: string) {}
}

export class SelectSelectableShipment {
  static readonly type = '[Wizard] Select Selectable Shipment';
  constructor(public shipment: ShipmentView) {}
}

export class DeselectSelectableShipment {
  static readonly type = '[Wizard] Deselect Selectable Shipment';
  constructor(public shipmentId: string) {}
}

export class SelectOrder {
  static readonly type = '[Wizard] Select Order';
  constructor(public orderId: string) {}
}

export class Set4PLCustomer {
  static readonly type = '[Wizard] Set 4PL Customer';
  constructor(public fourPLCustomer: FourPLCustomer) {}
}

export class Set4PlContract {
  static readonly type = '[Wizard] Set 4PL Contract';
  constructor(public fourPlContract: FourPlContract) {}
}

export class Fetch4PLCustomers {
  static readonly type = '[Wizard] Load 4PL Customers';
  constructor() {}
}

export class Fetch4PLContracts {
  static readonly type = '[Wizard] Load 4PL Contracts';
  constructor(public orderId: string) {}
}

export class Reset4PLContractsRelatedData {
  static readonly type = '[Wizard] Reset 4PL Related Data';
  constructor() {}
}

export class UploadOrderDocument {
  static readonly type = '[Wizard] Upload Order Document';
  constructor(
    public category: string,
    public file: UploadedFileType
  ) {}
}

export class RemoveShipmentWithReference {
  static readonly type = '[Wizard] Remove Shipment With Reference';
  constructor(public shipmentId: string) {}
}

export class UpdateActivity {
  static readonly type = '[Wizard] Update Activity';
  constructor(
    public activity: Activity,
    public plannedServiceId: string
  ) {}
}

export class DeleteOrder {
  static readonly type = '[Wizard] Delete Order';
  constructor(public orderId: string) {}
}

export class FetchPaginatedCategorizedOrders {
  static readonly type = '[Wizard] Fetch Paginated Categorized Orders';
  constructor(
    public jobRefId: string,
    public shipmentId: string,
    public selectedOrderId: string,
    public offset: number,
    public limit: number,
    public searchText: string = ''
  ) {}
}

export class ResetWizard {
  static readonly type = '[Wizard] Reset Wizard';
  constructor() {}
}

export class UpdateJobSmartenedInJobFile {
  static readonly type = '[Wizard] Update Job Smartened In Job File';
  constructor(
    public jobRefId: string,
    public smartened: boolean
  ) {}
}

export class AddHmtActivity {
  static readonly type = '[Wizard] Add Hmt Activity';
  constructor(
    public activity: Activity,
    public plannedServiceId: string,
    public previousActivityId: string
  ) {}
}

export class RemoveHmtActivity {
  static readonly type = '[Wizard] Remove Hmt Activity';
  constructor(
    public activityId: string,
    public plannedServiceId: string
  ) {}
}

export class RemoveServiceOption {
  static readonly type = '[Wizard] Remove Service Option';
  constructor(
    public shipmentId: string,
    public optionId: string
  ) {}
}

export class ResetOrderDocuments {
  static readonly type = '[Wizard] Reset Order Documents';
  constructor() {}
}

export class RemoveOrderFromShipment {
  static readonly type = '[Wizard] Remove Order From Shipment';
  constructor(
    public orderId: string,
    public shipmentId: string
  ) {}
}

export class DuplicateServiceOption {
  static readonly type = '[Wizard] Duplicate Service Option';
  constructor(
    public shipmentId: string,
    public serviceOptionId: string
  ) {}
}

export class UpdateServiceCard {
  static readonly type = '[Wizard] Update Service Card';
  constructor(public serviceCard: ServiceCard) {}
}

export class RemoveServiceCard {
  static readonly type = '[Wizard] Remove Service Card';
  constructor(public serviceCardId: string) {}
}

export class ResetServiceOptionState {
  static readonly type = '[Wizard] Reset Service Option State';
  constructor() {}
}

export class UpdateUnSmartServices {
  static readonly type = '[Wizard] Select UnSmart Services';
  constructor(public services: string[]) {}
}

export class CheckServiceValidationsStatus {
  static readonly type = '[Wizard] Check Service Validations Status';
  constructor(
    public jobRefId: string,
    public workflowId = ''
  ) {}
}
