import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnhancedDatagridNumberEditorParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-number-editor',
  templateUrl: './enhanced-datagrid-number-editor.component.html',
  styleUrls: ['./enhanced-datagrid-number-editor.component.scss'],
})
export class EnhancedDatagridNumberEditorComponent<TData = Record<string, unknown>> implements ICellEditorAngularComp {
  params!: EnhancedDatagridNumberEditorParams<TData>;
  initialText!: string;
  public textControl = new FormControl('');

  suffix: string | null = null;

  agInit(params: EnhancedDatagridNumberEditorParams<TData>): void {
    this.params = params;
    if (params.withSuffix && params.suffix) {
      this.suffix = params.suffix;
      this.initialText = params.value ? params.value.split(' ')[0] : '';
      this.textControl.setValue(this.initialText);
    } else {
      this.initialText = params.value;
      this.textControl.setValue(params.value);
    }
    if (params.calculateDefaultFrom && !params.data[params.colDef.field as string]) {
      const fromValue = this.params.data[params.calculateDefaultFrom.from];
      const toValue = this.params.data[params.calculateDefaultFrom.to];
      if (fromValue && toValue) {
        const fromAsDate = new Date(fromValue);
        const toAsDate = new Date(toValue);
        const diffTime = Math.abs(toAsDate.getTime() - fromAsDate.getTime());
        const diffDaysAsWholeNumberOrDecimal = diffTime / (1000 * 60 * 60 * 24);
        const formattedDiff =
          diffDaysAsWholeNumberOrDecimal >= 1
            ? Math.ceil(diffDaysAsWholeNumberOrDecimal).toString()
            : diffDaysAsWholeNumberOrDecimal.toFixed(1);
        this.textControl.setValue(formattedDiff);
      }
    }
  }

  getValue() {
    const value = this.textControl.value;
    if (!value) return '';
    if (typeof value === 'string') return Number(value);
    if (typeof value === 'number') return value;
    return '';
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Enter' && event.key !== 'Escape') {
      return;
    }
    if (event.key === 'Escape') {
      this.textControl.setValue(this.initialText);
    }
    this.params.stopEditing();
  }
}
