import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  private readonly exceptions = ['to', 'and', 'or', 'from', 'by', 'on', 'at', 'of', 'in', 'with', 'as', 'for', 'by'];

  transform(value: string): string {
    if (!value) return value;

    return value
      .split(' ')
      .map((word, index) =>
        this.exceptions.includes(word.toLowerCase()) && index !== 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  }
}
