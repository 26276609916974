<div [class.hidden]="!isVisible()" [formGroup]="ratesForm">
  <div class="rates-charging-structure--lcl">
    <div class="header">
      <div>Rates and Charging Structure</div>
      <div [class.select-disabled]="isDisabled()">
        <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
          <mat-label [class.select-disabled]="isDisabled()">Currency</mat-label>
          <mat-select formControlName="mainCurrency" [panelClass]="isDisabled() ? 'hidden' : ''">
            @for (currency of currencies$ | async; track currency.code) {
              <mat-option [value]="currency.code">{{ currency.code }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="rates-table-wrapper">
      <div class="rate-table-container">
        <table
          mat-table
          [dataSource]="[{ volumeWeight: 'Rate Per ' + (isAirFreight() ? 'Kg' : 'CBM') }]"
          class="rate-table"
          #ratesTable>
          <ng-container matColumnDef="volumeWeight">
            <th mat-header-cell *matHeaderCellDef>{{ isAirFreight() ? 'Weight' : 'Volume' }}</th>
            <td mat-cell *matCellDef="let row">{{ row.volumeWeight }}</td>
          </ng-container>

          <ng-container *ngFor="let column of columnsFormArray.controls; let i = index" [matColumnDef]="'weight_' + i">
            <th mat-header-cell *matHeaderCellDef [formGroup]="column">
              <div class="weight-column">
                <select class="no-arrow" formControlName="comparison" [disabled]="isDisabled()">
                  <option value="<">&lt;</option>
                  <option value=">">&gt;</option>
                </select>
                <input
                  formControlName="value"
                  min="0"
                  type="number"
                  class="editable-cell weight-input"
                  [readonly]="isDisabled()" />
                <span>{{ isAirFreight() ? 'Kg' : 'CBM' }}</span>
                @if (!isReadOnly()) {
                  <button mat-icon-button class="delete-button" (click)="removeColumn(i)" [disabled]="isDisabled()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
              </div>
            </th>
            <td mat-cell *matCellDef="let row">
              <input
                type="number"
                min="0"
                [formControl]="ratesFormArray.controls[i].controls['rate']"
                class="editable-cell"
                [readonly]="isDisabled()" />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedWeightColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedWeightColumns"></tr>
        </table>
      </div>
      @if (!isReadOnly()) {
        <div class="add-button-container">
          <button color="primary" (click)="addColumn()" [disabled]="isDisabled()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>

  <div>
    <div
      *ngFor="let charge of ratesForm.controls.additionalCharges.controls; let i = index"
      class="additional-charge-row"
      [formGroup]="charge">
      <mat-form-field appearance="outline" class="charge-type-field" subscriptSizing="dynamic">
        <mat-label>Additional Charge</mat-label>
        <input type="text" formControlName="chargeType" matInput [readonly]="isDisabled()" />
      </mat-form-field>
      <div class="amount-currency-pair">
        <mat-form-field appearance="outline" class="amount-field" subscriptSizing="dynamic">
          <mat-label>Amount</mat-label>
          <input matInput formControlName="chargeAmount" type="number" [readonly]="isDisabled()" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
          <mat-label>Currency</mat-label>
          <input matInput readonly [value]="ratesForm.controls.mainCurrency.value" [disabled]="isDisabled()" />
        </mat-form-field>
      </div>
      @if (!isReadOnly()) {
        <button mat-icon-button (click)="removeAdditionalCharge(i)" class="delete-btn" [disabled]="isDisabled()">
          <mat-icon>delete_outline</mat-icon>
        </button>
      }
    </div>
  </div>

  @if (!isReadOnly()) {
    <div class="add-additional-charge-btn">
      <button
        mat-stroked-button
        class="add-btn"
        color="primary"
        (click)="addAdditionalCharge()"
        [disabled]="isDisabled()">
        <mat-icon>add</mat-icon>
        Add New Fee
      </button>
    </div>
  }
</div>
