import { ChangeDetectorRef, Component, effect, inject, OnInit, viewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification/notification.service';
import { ServiceTypes } from 'app/modules/wizard/enums/service-types.enum';
import { first } from 'rxjs';
import { VesselSchedule } from '../../models/vessel-schedule.model';
import { UpdateVesselScheduleInput } from '../../queries/update-vessel-schedule.query';
import { VesselScheduleService } from '../../services/vessel-schedule.service';
import { CarrierScheduleFclChargingStructureComponent } from '../carrier-schedule-fcl-charging-structure/carrier-schedule-fcl-charging-structure.component';
import { CarrierScheduleLclChargingStructureComponent } from '../carrier-schedule-lcl-charging-structure/carrier-schedule-lcl-charging-structure.component';

@Component({
  selector: 'hmt-update-or-view-rate-structure-for-vessel-schedule',
  templateUrl: './update-or-view-rate-structure-for-vessel-schedule.component.html',
  styleUrls: ['./update-or-view-rate-structure-for-vessel-schedule.component.scss'],
})
export class UpdateOrViewRateStructureForVesselScheduleComponent implements OnInit {
  ServiceTypes = ServiceTypes;

  dialogRef = inject(MatDialogRef);
  notificationService = inject(NotificationService);
  vesselScheduleService = inject(VesselScheduleService);
  changeDetectorRef = inject(ChangeDetectorRef);

  fclForm = viewChild<CarrierScheduleFclChargingStructureComponent>('fclForm');
  lclForm = viewChild<CarrierScheduleLclChargingStructureComponent>('lclForm');

  dialogData = inject(MAT_DIALOG_DATA);
  vesselScheduleId = this.dialogData.vesselScheduleId;
  existingRateStructure = this.dialogData.existingRateStructure as VesselSchedule['rateStructure'];
  onSuccess = this.dialogData.onSuccess as () => void;
  isDisabled = this.dialogData.isDisabled as boolean;
  isReadOnly = this.dialogData.isReadOnly as boolean;
  onlyShow = this.dialogData.onlyShow as string | undefined;
  isOnlyFCL = this.onlyShow === 'FCL';
  isOnlyLCL = this.onlyShow === 'LCL';

  selectedShipmentMethod = new FormControl<'FCL' | 'LCL'>(this.isOnlyFCL ? 'FCL' : this.isOnlyLCL ? 'LCL' : 'FCL');

  constructor() {
    effect(() => {
      this.selectedShipmentMethod.value;
      this.changeDetectorRef.detectChanges();
    });
  }

  initFromExistingRateStructure() {
    if (this.existingRateStructure) {
      if (this.existingRateStructure.fcl) {
        this.fclForm().setFormValue({
          additionalCharges: this.existingRateStructure.fcl.rateCardData?.additionalCharges?.map(
            ({ amount, description }) => {
              return {
                chargeAmount: amount,
                chargeType: description,
              };
            }
          ),
          mainCurrency: this.existingRateStructure.fcl.rateCardData?.currencyCode,
          containerTypeUnitPrices: this.existingRateStructure.fcl.rateCardData.data.values.loadRates.map(
            ({ containerTypeAndSize, unitPrice }) => {
              return {
                containerType: containerTypeAndSize,
                unitPrice,
              };
            }
          ),
        });
      }
      if (this.existingRateStructure.lcl) {
        this.lclForm().setFormValue({
          additionalCharges: this.existingRateStructure.lcl.rateCardData?.additionalCharges?.map(
            ({ amount, description }) => {
              return {
                chargeAmount: amount,
                chargeType: description,
              };
            }
          ),
          columns: this.existingRateStructure.lcl.rateCardData.data.values.volumeWeightsRates.volumeWeights.values.map(
            value => {
              return {
                comparison: value < 0 ? '<' : '>',
                value: Math.abs(value),
              };
            }
          ),
          rates: this.existingRateStructure.lcl.rateCardData.data.values.volumeWeightsRates.rates.values.map(rate => {
            return {
              rate,
            };
          }),
          mainCurrency: this.existingRateStructure.lcl.rateCardData?.currencyCode,
        });
      }
    }
  }

  ngOnInit(): void {
    if (this.existingRateStructure) {
      this.initFromExistingRateStructure();
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    const fclFormValue = this.fclForm().getFormValue();
    const lclFormValue = this.lclForm().getFormValue();

    if (
      (this.selectedShipmentMethod.value === 'FCL' && !fclFormValue) ||
      (this.selectedShipmentMethod.value === 'LCL' && !lclFormValue)
    ) {
      this.notificationService.showError('Please fill all the required fields');
      return;
    }

    if (this.selectedShipmentMethod.value === 'FCL') {
      const updateVesselScheduleInput: UpdateVesselScheduleInput = {
        _id: this.vesselScheduleId,
        rateStructure: {
          fcl: {
            currencyCode: fclFormValue.mainCurrency,
            data: {
              schema: '',
              values: {
                loadRates: fclFormValue.containerTypeUnitPrices.map(({ containerType, unitPrice }) => {
                  return {
                    currency: fclFormValue.mainCurrency,
                    shipmentMethod: 'FCL',
                    containerTypeAndSize: containerType,
                    unitPrice: unitPrice,
                  };
                }),
              },
            },
            additionalCharges: fclFormValue.additionalCharges.map(({ chargeAmount, chargeType }) => {
              return {
                amount: chargeAmount,
                description: chargeType,
                currencyCode: fclFormValue.mainCurrency,
              };
            }),
          },
        },
      };

      this.vesselScheduleService
        .updateVesselSchedule(updateVesselScheduleInput)
        .pipe(first())
        .subscribe({
          next: () => {
            this.notificationService.showSuccess('Vessel schedule rate structure updated successfully');
            this.onSuccess();
            this.close();
          },
          error: () => {
            this.notificationService.showError('Failed to update vessel schedule rate structure');
          },
        });
    } else if (this.selectedShipmentMethod.value === 'LCL') {
      const lclFormValue = this.lclForm().getFormValue();

      const updateVesselScheduleInput: UpdateVesselScheduleInput = {
        _id: this.vesselScheduleId,
        rateStructure: {
          lcl: {
            currencyCode: lclFormValue.mainCurrency,
            additionalCharges: lclFormValue.additionalCharges.map(({ chargeAmount, chargeType }) => {
              return {
                amount: chargeAmount,
                description: chargeType,
                currencyCode: lclFormValue.mainCurrency,
              };
            }),
            data: {
              schema: '',
              values: {
                volumeWeightsRates: {
                  volumeWeights: {
                    title: 'Volume Weight',
                    unit: 'Kg',
                    values: lclFormValue.columns.map(({ comparison, value }) => {
                      if (comparison === '<') {
                        return value * -1;
                      }
                      return value;
                    }),
                  },
                  rates: {
                    title: 'Rate per Kg',
                    unit: 'Kg',
                    values: lclFormValue.rates.map(({ rate }) => rate),
                  },
                },
              },
            },
          },
        },
      };

      this.vesselScheduleService
        .updateVesselSchedule(updateVesselScheduleInput)
        .pipe(first())
        .subscribe({
          next: () => {
            this.notificationService.showSuccess('Vessel schedule rate structure updated successfully');
            this.onSuccess();
            this.close();
          },
          error: () => {
            this.notificationService.showError('Failed to update vessel schedule rate structure');
          },
        });
    }
  }
}
