import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { LocationNamesService } from '@shared/services/location-names.service';
import { VesselScheduleDetailsService } from '@shared/services/vessel-schedule-details.service';
import { EnhancedColDef } from '@shared/types/enhanced-grid-types/enhanced-grid-common.type';
import { EnhancedDatagridActionsRendererParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { UpdateOrViewRateStructureForVesselScheduleComponent } from 'app/modules/catalog-manager/components/update-or-view-rate-structure-for-vessel-schedule/update-or-view-rate-structure-for-vessel-schedule.component';
import { VesselSchedule, VesselScheduleTableRow } from 'app/modules/catalog-manager/models/vessel-schedule.model';
import { EnhancedDatagridActionsRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-actions-renderer/enhanced-datagrid-actions-renderer.component';
import { EnhancedDatagridPortRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-port-renderer/enhanced-datagrid-port-renderer.component';
import { EnhancedDatagridRateStructureRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-rate-structure-renderer/enhanced-datagrid-rate-structure-renderer.component';
import { EnhancedDatagridRoutingRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-routing-renderer/enhanced-datagrid-routing-renderer.component';
import { EnhancedDatagridTransitTimeRendererComponent } from '../enhanced-datagrid/components/enhanced-datagrid-transit-time-renderer/enhanced-datagrid-transit-time-renderer.component';

@Component({
  selector: 'hmt-long-term-location-pair-vessel-schedule-table',
  templateUrl: './long-term-location-pair-vessel-schedule-table.component.html',
  styleUrl: './long-term-location-pair-vessel-schedule-table.component.scss',
})
export class LongTermLocationPairVesselScheduleTableComponent implements OnInit {
  vesselScheduleDetailsService = inject(VesselScheduleDetailsService);
  locationNamesService = inject(LocationNamesService);
  notificationService = inject(NotificationService);
  dialogHandlerService = inject(DialogHandlerService);

  vesselScheduleGroup = input.required<{
    name: string;
    carrierReferences: {
      carrierRef: string;
    }[];
    shipmentMode: string;
  }>();

  vesselSchedules = signal<VesselSchedule[]>([]);
  rowData = signal<VesselScheduleTableRow[]>([]);
  loading = signal(false);
  loadingLocationDetails = signal(false);

  isBiddingWizard = input<boolean>(false);
  deleteRow = output<VesselSchedule>();

  async ngOnInit() {
    this.loading.set(true);
    try {
      const schedules = await this.vesselScheduleDetailsService.getVesselSchedules(
        this.vesselScheduleGroup().carrierReferences.map(c => c.carrierRef)
      );
      this.vesselSchedules.set(schedules);
      this.loadingLocationDetails.set(true);
      const mappedVesselSchedules = await Promise.all(this.vesselSchedules().map(this.vesselScheduleToRow.bind(this)));
      this.rowData.set(mappedVesselSchedules as VesselScheduleTableRow[]);
      this.loadingLocationDetails.set(false);
    } catch (error) {
      console.error('Error fetching vessel schedules', error);
      this.notificationService.showError('Failed to fetch vessel schedules');
    } finally {
      this.loading.set(false);
    }
  }

  colDefs = computed<EnhancedColDef<VesselScheduleTableRow>[]>(() => {
    const baseColDefs: EnhancedColDef<VesselScheduleTableRow>[] = [
      {
        field: 'vesselName' as keyof VesselScheduleTableRow,
        headerName: 'Vessel Name',
        pinned: 'left',
        colType: 'text',
        editable: false,
        // cellEditorParams: { labelForInput: 'Enter vessel name' },
      },
      {
        field: 'shippingLine',
        headerName: 'Shipping Line',
        colType: 'text',
        editable: false,
        // cellEditorParams: { labelForInput: 'Enter shipping line' },
      },
      {
        field: 'voyage',
        headerName: 'Voyage',
        colType: 'text',
        editable: false,
      },
      {
        field: 'previousPortOfCall',
        headerName: 'Previous Port of Call',
        editable: false,

        colType: 'port',
        // cellEditor: EnhancedDatagridPortEditorComponent,
        cellRenderer: EnhancedDatagridPortRendererComponent,
        valueGetter: params => params.data.previousPortOfCall,
      },
      {
        field: 'previousPortOfCallEta',
        headerName: 'Previous Port of Call ETA',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     labelForInput: 'Enter ETA for Previous Port of Call',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //   };
        // },
      },
      {
        field: 'previousPortOfCallEtd',
        headerName: 'Previous Port of Call ETD',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     labelForInput: 'Enter ETD for Previous Port of Call',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //     helperMessage: 'Previous Port of Call ETD must be after Previous Port of Call ETA and before From ETA',
        //   };
        // },
      },
      {
        field: 'from',
        headerName: 'From',
        editable: false,

        // cellEditor: EnhancedDatagridPortEditorComponent,
        valueGetter: params => params.data.from,
        colType: 'port',
        cellRenderer: EnhancedDatagridPortRendererComponent,
      },
      {
        field: 'fromEta',
        headerName: 'From ETA',
        editable: false,
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     helperMessage: 'From ETA must be after Previous Port of Call ETD and before From ETD',
        //     labelForInput: 'Enter ETA for From Port',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //   };
        // },
        colType: 'datetime',
      },
      {
        field: 'fromEtd',
        headerName: 'From ETD',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     labelForInput: 'Enter ETD for From Port',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //     helperMessage: 'From ETD must be after From ETA and before To ETA',
        //   };
        // },
      },
      {
        field: 'cfsCutoff',
        headerName: 'CFS Cut Off',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: () => {
        //   return {
        //     labelForInput: 'Enter CFS Cut Off',
        //   };
        // },
      },
      {
        field: 'cyCutoffDate',
        headerName: 'CY Cutoff Date',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: () => {
        //   return {
        //     labelForInput: 'Enter CY Cutoff Date',
        //   };
        // },
      },
      {
        field: 'to',
        headerName: 'To',
        editable: false,

        colType: 'port',
        // cellEditor: EnhancedDatagridPortEditorComponent,
        cellRenderer: EnhancedDatagridPortRendererComponent,
        valueGetter: params => params.data.to,
      },
      {
        field: 'toEta',
        headerName: 'To ETA',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     labelForInput: 'Enter ETA for To Port',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //     helperMessage: 'To ETA must be after From ETD and before To ETD',
        //   };
        // },
      },
      {
        field: 'toEtd',
        headerName: 'To ETD',
        editable: false,

        colType: 'datetime',
        // cellEditorParams: params => {
        //   const validationBounds = this.getDateValidationBounds(params.column.getColId(), params.data);
        //   return {
        //     labelForInput: 'Enter ETD for To Port',
        //     minDate: validationBounds.minDate,
        //     maxDate: validationBounds.maxDate,
        //     helperMessage: 'To ETD must be after To ETA',
        //   };
        // },
      },

      {
        field: 'totalTransitTime',
        headerName: 'Total Transit Time',
        colType: 'custom',
        editable: false,
        cellRenderer: EnhancedDatagridTransitTimeRendererComponent,
        // cellEditorParams: { labelForInput: 'Enter Total Transit Time', withSuffix: true, suffix: 'days' },
        valueGetter: params => (params.data.totalTransitTime ? `${params.data.totalTransitTime} days` : undefined),
      },
      {
        field: 'routingDetails',
        headerName: 'Routing',
        colType: 'routing',
        editable: false,
        valueGetter: params => params.data.routingDetails?.stops,
        cellRenderer: EnhancedDatagridRoutingRendererComponent,
        // cellEditor: EnhancedDatagridRoutingEditorComponent,
        // cellEditorParams: {
        //   for: 'vessels',
        // },
      },
      {
        colType: 'custom',
        headerName: 'Rate Structure',
        cellRenderer: EnhancedDatagridRateStructureRendererComponent,
        editable: false,
        cellRendererParams: params => {
          return {
            openDrawer: this.openRateStructureDrawer.bind(this, params.data),
          };
        },
      },
    ];

    if (!this.isBiddingWizard()) return baseColDefs;

    return [
      ...baseColDefs,
      {
        headerName: 'Remove Schedule',
        colType: 'actions',
        cellRenderer: EnhancedDatagridActionsRendererComponent,
        cellRendererParams: {
          deleteCallback: this.setDeleteRow.bind(this),
          isDeletable: true,
        } as EnhancedDatagridActionsRendererParams<VesselScheduleTableRow>,
      },
    ];
  });

  setDeleteRow(row: VesselSchedule) {
    this.vesselSchedules.update(schedules => schedules.filter(schedule => schedule._id !== row._id));
    this.rowData.update(rows => rows.filter(r => r._id !== row._id));
    this.deleteRow.emit(row);
  }

  async vesselScheduleToRow(vesselSchedule: VesselSchedule): Promise<VesselScheduleTableRow> {
    const fromName = vesselSchedule.from ? await this.locationNamesService.getLocationName(vesselSchedule.from) : null;
    const toName = vesselSchedule.to ? await this.locationNamesService.getLocationName(vesselSchedule.to) : null;
    const previousPortOfCallName = vesselSchedule.previousPortOfCall
      ? await this.locationNamesService.getLocationName(vesselSchedule.previousPortOfCall)
      : null;

    const row = {
      ...vesselSchedule,
      from: fromName
        ? {
            id: vesselSchedule.from,
            name: fromName.name,
            shortName: fromName.shortName,
          }
        : undefined,
      to: toName
        ? {
            id: vesselSchedule.to,
            name: toName.name,
            shortName: toName.shortName,
          }
        : undefined,
      previousPortOfCall: previousPortOfCallName
        ? {
            id: vesselSchedule.previousPortOfCall,
            name: previousPortOfCallName.name,
            shortName: previousPortOfCallName.shortName,
          }
        : undefined,
      routingDetails: vesselSchedule.routingDetails
        ? {
            type: vesselSchedule.routingDetails.type,
            stops: (await Promise.all(
              vesselSchedule.routingDetails.stops.map(async stop => {
                const { name, shortName } = await this.locationNamesService.getLocationName(stop);
                return {
                  id: stop,
                  name: name,
                  shortName: shortName,
                };
              })
            )) as [
              {
                id: string;
                name: string;
                shortName: string;
              },
              {
                id: string;
                name: string;
                shortName: string;
              },
            ],
          }
        : null,
    };
    return row;
  }

  openRateStructureDrawer(row: VesselScheduleTableRow) {
    this.dialogHandlerService.openDialog(UpdateOrViewRateStructureForVesselScheduleComponent, {
      vesselScheduleId: row._id,
      existingRateStructure: row.rateStructure,
      isDisabled: true,
      isReadOnly: true,
      onlyShow: this.vesselScheduleGroup().shipmentMode,
    });
  }
}
