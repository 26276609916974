import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'hmt-enhanced-datagrid-transit-time-renderer',
  templateUrl: './enhanced-datagrid-transit-time-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-transit-time-renderer.component.scss'],
})
export class EnhancedDatagridTransitTimeRendererComponent implements ICellRendererAngularComp {
  private readonly MS_PER_DAY = 24 * 60 * 60 * 1000;
  private readonly MS_PER_HOUR = 60 * 60 * 1000;

  days = 0;
  hours = 0;

  agInit(params: ICellRendererParams): void {
    const value = Number(params.data[params.colDef.field as string]) || 0;

    // Convert milliseconds to days and hours
    this.days = Math.floor(value / this.MS_PER_DAY);
    const remainingMs = value % this.MS_PER_DAY;
    this.hours = Math.ceil(remainingMs / this.MS_PER_HOUR);
  }

  refresh(params: ICellRendererParams): boolean {
    const value = Number(params.value) || 0;

    this.days = Math.floor(value / this.MS_PER_DAY);
    const remainingMs = value % this.MS_PER_DAY;
    this.hours = Math.ceil(remainingMs / this.MS_PER_HOUR);

    return true;
  }
}
